<template>
  <div class="flow">
    <PageHeader />
    <div class="header-top yyhb">
      <Header />
      <div class="header-text">
        <div class="header-title">
          语音红包
        </div>
        <div class="header-sign">
          “ 读出来那一刻，我强烈的get到了推广产品的核心价值 ”
        </div>
      </div>
    </div>
    <div class="content-demonstration">
      <div class="demonstration-title">
        语音红包场景演示
      </div>
      <div class="demonstration-line" />
      <div class="demonstration-bar">
        <!-- 用户在合适的场景获得推广产品的语音红包 -->
      </div>
      <div class="demonstration-phone-bar">
        <img
          src="../img/demonstration-phone-bar.png"
          alt=""
        >
      </div>
      <div class="demonstration-phone">
        <div class="demonstration-phone-warp">
          <img
            src="../img/demonstration-phone.png"
            alt=""
          >
        </div>
        <div class="demonstration-phone-img">
          <video
            src="https://voicefile.xinliangxiang.com/video/mp4.mp4"
            autoplay="autoplay"
            loop="loop"
            id="video"
            :muted="muted"
          />
        </div>
        <div class="demonstration-warp">
          <transition name="fade">
            <div
              v-show="videoPhoneTitle==2"
              class="demonstration-text-item demonstration1"
            >
              <div class="demonstration-text-item-box">
                <div class="demonstration-text-item-title">
                  进入语音红包
                </div>
              </div>
              <div class="demonstration-text-item-line">
                <img
                  src="../img/demonstration-text-item-line.png"
                  alt=""
                >
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div
              v-show="videoPhoneTitle==3"
              class="demonstration-text-item demonstration2"
            >
              <div class="demonstration-text-item-line">
                <img
                  src="../img/demonstration-text-item-line2.png"
                  alt=""
                >
              </div>
              <div class="demonstration-text-item-box">
                <div class="demonstration-text-item-title">
                  真人播音，对推广产品进行介绍
                </div>
                <div class="demonstration-text-item-text">
                  图片、字幕、播音三者合一，让用户对推广产品价值有详细的了解与认识
                </div>
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div
              v-show="videoPhoneTitle==4"
              class="demonstration-text-item demonstration6"
            >
              <div class="demonstration-text-item-line">
                <img
                  src="../img/demonstration-text-item-line.png"
                  alt=""
                >
              </div>
              <div class="demonstration-text-item-box">
                <div class="demonstration-text-item-title">
                  推广产品结束介绍，口读设置的口令文案
                </div>
                <div class="demonstration-text-item-text">
                  口令文案：是推广产品对用户最具吸引力的核心价值文案
                </div>
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div
              v-show="videoPhoneTitle==5"
              class="demonstration-text-item demonstration3"
            >
              <div class="demonstration-text-item-box">
                <div class="demonstration-text-item-title">
                  口读完成，口令文案动效依次显示
                </div>
                <div class="demonstration-text-item-text">
                  朗读成功，对核心价值口令文案动效依次显示，把核心价值口令再次强输入，进一步加强用户对核心价值的感知
                </div>
              </div>
              <div class="demonstration-text-item-line">
                <img
                  src="../img/demonstration-text-item-line3.png"
                  alt=""
                >
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div
              v-show="videoPhoneTitle==1"
              class="demonstration-text-item demonstration4"
            >
              <div class="demonstration-text-item-box">
                <div class="demonstration-text-item-title">
                  用户在适合的场景获得推广产品的语音红包
                </div>
              </div>
              <div class="demonstration-text-item-line">
                <img
                  src="../img/demonstration-text-item-line4.png"
                  alt=""
                >
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div
              v-show="videoPhoneTitle==6"
              class="demonstration-text-item demonstration5"
            >
              <div class="demonstration-text-item-line">
                <img
                  src="../img/demonstration-text-item-line5.png"
                  alt=""
                >
              </div>
              <div class="demonstration-text-item-box">
                <div class="demonstration-text-item-title">
                  最后一步，转化用户
                </div>
                <div class="demonstration-text-item-text">
                  通过口读互动，让用户强烈的get到了推广产品的核心价值。  
                </div>
                <div class="demonstration-text-item-text">
                  都为了最后这一步，能够更充分的促进每一个用户的转化。
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="content-list">
      <div class="content-list-title">
        语音红包：一切皆为提升流量价值
      </div>
      <div class="content-list-line" />
      <div class="content">
        <div class="section">
          <div class="section-info">
            <div class="info-num">
              01
            </div>
            <div class="info-title">
              更深的广告价值感知
            </div>
            <img
              src="../img/flow-line.png"
              alt=""
              class="info-line"
            >
            <div class="info-text">
              <div class="info-text-title">
                口读互动：
              </div>
              <div class="info-text-desc">
                用户朗读口令文案时，产品的核心价值已经深深植入用户
                潜意识，对产品价值有更深的感知。
              </div>
            </div>
            <div class="info-text">
              <div class="info-text-title">
                真人播报：
              </div>
              <div class="info-text-desc">
                产品宣传图与真人语音播报、字幕屏显相应形式，用户更
                直观、更明确了解产品特性与价值
              </div>
            </div>
            <!-- <div
              class="info-btn"
              @click="goRouter('flow')"
            >
              <img
                src="../img/info-btn.png"
                alt=""
              >
            </div> -->
          </div>

          <div class="section-imgs">
            <div class="yyhb-img1-4">
              <img
                src="../img/yyhb-img1-4.png"
                alt=""
              >
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-imgs">
            <img
              class="yyhb-img1-5"
              src="../img/yyhb-img1-5.png"
              alt=""
            >
          </div>
          <div class="section-info">
            <div class="info-num">
              02
            </div>
            <div class="info-title">
              提升广告效果
            </div>

            <img
              src="../img/flow-line.png"
              alt=""
              class="info-line"
            >
            <div class="info-text">
              <div class="info-text-title">
                自然转化：
              </div>
              <div class="info-text-desc">
                通过图片或视频+字幕+播音三合一形式与口读互动方式
                加强对推广产品的价值感知与了解，产生自然真实有效转化。
              </div>
            </div>
            <div class="info-text">
              <div class="info-text-title">
                成本可控：
              </div>
              <div class="info-text-desc">
                通过oCPR出价方式，有效控制获客成本，让计费流量的
                指向性行为更有价值。
              </div>
            </div>
            <div
              class="info-btn"
              @click="goRouter('effect')"
            >
              <img
                src="../img/info-btn.png"
                alt=""
              >
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-info">
            <div class="info-num">
              03
            </div>
            <div class="info-title">
              提升媒体收益
            </div>
            <img
              src="../img/flow-line.png"
              alt=""
              class="info-line"
            >
            <div class="info-text">
              <div class="info-text-title">
                流量复用：
              </div>
              <div class="info-text-desc">
                新的形式让媒体流量（特别是老用户）可以再一次被价值
                化。
              </div>
            </div>
            <div class="info-text">
              <div class="info-text-title">
                转化价值高：
              </div>
              <div class="info-text-desc">
                广告效果的有效提升，可以长久保证媒体收益。
              </div>
            </div>
            <div
              class="info-btn"
              @click="goRouter('flow')"
            >
              <img
                src="../img/info-btn.png"
                alt=""
              >
            </div>
          </div>
          <div class="section-imgs">
            <img
              class="flow-img2-1"
              src="../img/yyhb-img1-6.png"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>
    <div class="join-box">
      <div class="join-title">
        现在注册 立即体验新量象服务
      </div>
      <div class="join-desc">
        <!-- 新量象流量价值平台是团队将公司旗下产品核心技术及广告数据库整合成为一个开放引擎，可以便捷接入流量方，轻松为流量主实现流量高收益变现。 -->
      </div>
      <div
        class="join-btn"
        @click="handleClickDialog"
      >
        注册 / 登录
      </div>
    </div>
    <div class="footer-content">
      <PageFooter />
    </div>
  </div>
</template>

<script>
import Header from '@/components/header'
import PageHeader from '@/components/PageHeader'
import PageFooter from '@/components/PageFooter'
import { mapState } from 'vuex'

export default {
  components: {
    Header,
    PageHeader,
    PageFooter
  },
  data() {
    return {
      phoneText: 0, //语音红包手机模型切换展示
      muted:true, //视频自动播放
      videoPhoneTitle:1, //视频打点
      timer: ''
    }
  },
  computed: {
    ...mapState(['showDialog']),
  },
  mounted(){
    this.videoProgressActive()
  },
  methods: {
    // 语音红包手机轮播图片
    chengePhone(e) {
      this.phoneText = e
    },
    handleClickDialog() {
      if (!this.showDialog) {
        this.$store.commit('changeDialog', true)
      }
    },
    //视频打点
    videoProgressActive(){
      var that =this
      that.timer = setTimeout(function () {
        var vid = document.getElementById("video");
        if (!vid) {
          clearTimeout(that.timer)
        } else {
          vid.play()
          var currentTime=vid.currentTime.toFixed(0);
          if(currentTime==0&&currentTime<2){
            that.videoPhoneTitle = 1
          }else if(currentTime>2&&currentTime<5){
            that.videoPhoneTitle = 2
          }else if(currentTime>5&&currentTime<23){
            that.videoPhoneTitle = 3
          }else if(currentTime>23&&currentTime<36){
            that.videoPhoneTitle = 4
          }else if(currentTime>36&&currentTime<44){
            that.videoPhoneTitle = 5
          }else if(currentTime>44){
            that.videoPhoneTitle = 6
          }
          that.videoProgressActive();
        }
      }, 1000);
      
    },
     //页面跳转
    goRouter(name){
      this.$router.push(name)
    }
  }
};
</script>

<style lang="scss" scoped>
.header-top{
  overflow: hidden;
  position: relative;
  // min-height: 285px;
  background: url('../img/yyhb-banner.png');
  //background-repeat: no-repeat;
  width: 100%;
  height: auto;
  margin-bottom: 0;
}
.header-text{
  // position: absolute;
  margin-top: 166px;
  margin-left: 260px;
  margin-bottom: 70px;
}
.header-title{
  font-size: 50px;
  line-height: 50px;
  margin-bottom: 30px;
  color: #fff;
  //font-family: 'fzqk';
}
.header-sign{
  font-size: 24px;
  line-height: 24px;
  color:rgba(255,255,255,0.75)
}
.content{
  width: 70%;
}
.footer-content{
  padding: 0 250px;
}
.section{
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 140px;
  &-info{
    .info-num{
      font-size:50px;
      height: 50px;
      line-height: 50px;
      color:rgba(254,233,203,1);
    }
    .info-title{
      font-size:56px;
      //font-family:'fzqk';
      height: 55px;
      line-height: 55px;
      color:rgba(102,102,102,1);
      margin-bottom: 22px;
      margin-top: -25px;
    }
    .info-line{
      width: 45px;
      height: 8px;
      margin-bottom: 25px;
    }
    .info-text{
      display: flex;
      margin-bottom: 35px;
      align-items: flex-start;
      &-title{
        color: #FF8400;
        font-size: 18px;
        line-height: 18px;
        margin-top: 15px;
      }
      &-desc{
        font-size:18px;
        color:#666666;
        line-height:48px;
        max-width: 456px;
      }
    }
    .info-icon-title{
      font-size:18px;
      color:rgba(255,132,0,1);
      line-height:18px;
      margin-bottom: 30px;
    }
    .info-icon-list{
      display: flex;
      align-items: center;
    }
    .info-icon-item{
      margin-right: 40px;
      font-size:14px;
      line-height: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color:rgba(102,102,102,1);
      img{
        width: 56px;
        height: 56px;
        margin-bottom: 7px;
      }
    }
  }
  &-imgs{
    display: flex;
    img{
      width: 225px;
      height: 400px;
    }
    img:nth-child(2){
      margin: -30px -18px 0;
    }
  }
}
.join-box{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height:480px;
  background:linear-gradient(-45deg,rgba(255,96,0,1),rgba(255,144,0,1));
  margin-bottom: 60px;
  box-sizing: border-box;
  padding-top: 83px;
  .join-title{
    height:50px;
    line-height: 50px;
    font-size:50px;
    // font-family: 'fzqk';
    font-weight:bold;
    color:rgba(255,255,255,1);
    margin-bottom: 45px;
  }
  .join-desc{
    font-size:22px;
    color:rgba(255,255,255,1);
    line-height:48px;
    margin-bottom: 47px;
    text-align: center;
  }
  .join-btn{
    width:300px;
    height:68px;
    background:rgba(255,255,255,0.15);
    border:2px solid rgba(255,255,255,1);
    border-radius:34px;
    font-size:30px;
    //font-family:'fzqk';
    font-weight:bold;
    text-align: center;
    line-height: 68px;
    cursor: pointer;
    color:rgba(255,255,255,1);
  }
}
.content-demonstration{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: linear-gradient(90deg, #FF7200, #FFA200);
  position: relative;
  overflow: hidden;
  height: 720px;
  .demonstration-title{
    font-size: 31px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 24px;
    margin-top: 53px;
  }
  .demonstration-line{
    width: 53px;
    height: 5px;
    background: #FFFFFF;
    margin-top: 26px;
  }
  .demonstration-bar{
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 24px;
    margin-top: 24px;
  }
  .demonstration-phone-bar{
    position: absolute;
    top: 103px;
    left: 101px;
    width: 707px;
    height: 707px;
  }
  .demonstration-phone{
    width: 341px;
    height: 583px;
    margin-left: -341px;
    margin-top: 63px;
    position: relative;
    .demonstration-phone-warp{
      width: 300px;
      height: 583px;
      position: absolute;
      z-index: 2;
    }
    .demonstration-phone-img{
       width: 89%;
       height: 100%;
       position: absolute;
       z-index: 1;
       top: 3%;
      video{
        width: 100%;
        height: 100%;
      }
    }
  }
  .demonstration-warp{
    position: relative;
    width: 341px;
    height: 583px;
    margin-top: 24px;
    z-index: 3;
  }
  .demonstration-text-item{
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 220px;
    top: 100px;
    .demonstration-text-item-line{
      width: 265px;
      height: 67px;
      margin-top: 15px;
    }
    .demonstration-text-item-box{
      width: 139px;
      height: 53px;
      background: #FFF4E9;
      border-radius: 9px;
      box-sizing: border-box;
      margin-left: 127px;
      .demonstration-text-item-title{
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FF7800;
        text-align: center;
        line-height: 53px;
      }
      .demonstration-text-item-text{
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 23px;
        margin-top: 23px;
      }
    }
  }
  .demonstration2{
    left: 240px;
    top: 70px;
      .demonstration-text-item-line{
        width: 323px;
        height: 97px;
      }
      .demonstration-text-item-box{
        width: 229px;
        min-height: 130px;
        background: #FFF4E9;
        border-radius: 6px;
        padding: 15px 20px;
        margin-top: -80px;
        margin-left: 100px;
        .demonstration-text-item-title{
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FF7800;
          line-height: 19px;
          text-align: left;
        }
        .demonstration-text-item-text{
          font-size: 9px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 15px;
          margin-top: 15px;
        }
      }
  }
  .demonstration3{
    left: 240px;
    top: 243px;
     .demonstration-text-item-line{
        width: 338px;
        height: 97px;
        margin-top: -80px;
      }
      .demonstration-text-item-box{
        width: 229px;
        min-height: 144px;
        background: #FFF4E9;
        border-radius: 6px;
        padding: 15px 20px;
        margin-top: 0;
        margin-left: 110px;
        .demonstration-text-item-title{
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FF7800;
          line-height: 19px;
          text-align: left;
        }
        .demonstration-text-item-text{
          font-size: 9px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 15px;
          margin-top: 15px;
        }
      }
  }
  .demonstration4{
    left: 240px;
    top: 300px;
     .demonstration-text-item-line{
        width: 357px;
        height: 68px;
        margin-top: -50px;
      }
      .demonstration-text-item-box{
        width: 229px;
        min-height: 70px;
        background: #FFF4E9;
        border-radius: 6px;
        padding: 15px 20px;
        margin-top: 0px;
        margin-left: 130px;
        .demonstration-text-item-title{
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FF7800;
          line-height: 20px;
          text-align: left;
        }
        .demonstration-text-item-text{
          font-size: 9px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 15px;
          margin-top: 15px;
        }
      }
  }
  .demonstration5{
    width: 349px;
    height: 114px;
     .demonstration-text-item-line{
        width: 323px;
        height: 97px;
      }
      .demonstration-text-item-box{
        width: 229px;
        min-height: 140px;
        background: #FFF4E9;
        border-radius: 6px;
        padding: 15px 20px;
        margin-top: -80px;
        margin-left: 110px;
        .demonstration-text-item-title{
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FF7800;
          line-height: 19px;
          text-align: left;
        }
        .demonstration-text-item-text{
          font-size: 9px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 15px;
          margin-top: 15px;
        }
      }
  }
  .demonstration6{
    .demonstration-text-item-line{
            width: 453px;
            height: 136px;
          }
    .demonstration-text-item-box{
        width: 300px;
        min-height: 120px;
        background: #FFF4E9;
        border-radius: 6px;
        padding: 15px 20px;
        margin-top: -110px;
        margin-left: 150px;
        .demonstration-text-item-title{
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FF7800;
          line-height: 29px;
          text-align: left;
        }
        .demonstration-text-item-text{
          font-size: 9px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 23px;
          margin-top: 15px;
        }
      }
  }
}
.content-list{
  display: flex;
  flex-direction: column;
  align-items: center;
  .content-list-title{
    font-size: 31px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #444444;
    line-height: 24px;
    margin-top: 150px;
  }
  .content-list-line{
    width: 53px;
    height: 5px;
    background: linear-gradient(90deg, #FF7200, #FFA200);
    margin-top: 27px;
  }
  .info-title{
    font-size: 24px;
  }
  .info-btn{
    width: 116px;
    height: 59px;
    cursor: pointer;
  }
}
.yyhb-img1-4{
  width: 387px;
  height: 327px;
  img{
    width: 100%;
    height: 100%;
  }
}
.yyhb-img1-5{
  width: 346px;
  height: 251px;
}
.flow-img2-1{
  width: 387px;
  height: 257px;
}
@media screen and (max-width: 1440px) {
  .header{
    height: 285px;
  }
  .header-title{
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .header-text{
    top: 125px;
    left: 195px;
  }
  .header-sign{
    font-size: 15px;
    line-height: 15px;
  }
  .content{
    padding: 0 225px;
  }
  .footer-content{
    padding: 0 225px;
  }
  .section{
    padding-top: 77px;
    margin-bottom: 103px;
    &-info{
      .info-num{
        font-size:38px;
        height: 38px;
        line-height: 38px;
      }
      .info-title{
        font-size:24px;
        height: 42px;
        line-height: 42px;
        margin-bottom: 16px;
        margin-top: -20px;
      }
      .info-line{
        width: 34px;
        height: 5px;
        margin-bottom: 19px;
      }
      .info-text{
        margin-bottom: 24px;
        &-title{
          font-size: 14px;
          line-height: 14px;
          margin-top: 11px;
        }
        &-desc{
          max-width: 353px;
          font-size:14px;
          line-height:36px;
        }
      }
      .info-icon-title{
        font-size:14px;
        line-height:14px;
        margin-bottom:23px;
      }
      .info-icon-item{
        margin-right: 30px;
        font-size:12px;
        line-height: 12px;
        img{
          width: 42px;
          height: 42px;
          margin-bottom: 7px;
        }
      }
    }
    &-imgs{
      display: flex;
      img{
        width: 170px;
        height: 302px;
      }
      img:nth-child(2){
        margin: -22px -13px 0;
      }
    }
  }
  .join-box{
    height:360px;
    margin-bottom: 44px;
    padding-top: 62px;
    .join-title{
      height:38px;
      line-height: 38px;
      font-size:38px;
      margin-bottom: 33px;
    }
    .join-desc{
      font-size:16px;
      line-height:36px;
    }
    .join-btn{
      width:222px;
      height:50px;
      background:rgba(255,255,255,0.15);
      border:2px solid rgba(255,255,255,1);
      border-radius:25px;
      font-size:22px;
      line-height: 50px;
    }
  }
  .yyhb-img1-4{
    width: 387px;
    height: 327px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .yyhb-img1-5{
    width: 346px;
    height: 251px;
  }
  .flow-img2-1{
    width: 387px;
    height: 257px;
  }
}
@media screen and (min-width: 1445px) {
// .header-top{
//     height: 500px;
// }
.header-title{
    font-size: 40px;
    line-height: 36px;
    margin-bottom: 30px;
}
.header-sign{
    font-size: 20px;
  }
}
.main{
        display: flex;
        flex-direction: column;
        .item{
            display: flex;
            flex-direction: column;
            padding: 10px 30px;
            margin-bottom: 20px;
            .flow-path{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 36.45px;
                .flow-path-img{
                    width: 1035px;
                    height: 137px;
                }
                .flow-path-list{
                    display: flex;
                    flex-direction: row;
                    width: 1035px;
                    text-align: center;
                    .flow-path-item{
                        margin-left: 55.93px;
                        .number{
                            font-size: 14px;
                            //font-family: PingFang SC;
                            font-weight: bold;
                            color: #249CEB;
                            line-height: 27px;
                        }
                        .text{
                            font-size: 18px;
                            //font-family: PingFang SC;
                            font-weight: 400;
                            color: #000000;
                            line-height: 27px;
                        }
                    }
                    .secend{
                        margin-left: 195px;
                    }
                    .three{
                        margin-left: 204px;
                    }
                }
            }
            .advantage{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 30px;
                .title{
                    width: 350px;
                    height: 27px;
                }
                .title-secend{
                    width: 541px;
                    height: 30px;
                    margin-top: 55px;
                }
                .advantage-list{
                    width: 883px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-top: 53px;
                    .advantage-item{
                        display: flex;
                        flex-direction: column;
                        .header{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: 170px;
                            .header-img{
                                width: 96px;
                                height: 96px;
                            }
                            .header-text-main{
                                font-size: 23px;
                                //font-family: FZQingKeBenYueSongS-R-GB;
                                font-weight: 400;
                                color: #FF7800;
                                line-height: 47px;
                            }
                            .header-line{
                                width: 44px;
                                height: 3px;
                                background: #FFDDB8;
                                border-radius: 1px;
                            }
                        }
                        .other-text{
                            margin-top: 17px;
                            width: 170px;
                            font-size: 12px;
                            //font-family: PingFang SC;
                            font-weight: 400;
                            color: #777777;
                            line-height: 21px;
                            text-align: center;
                        }
                    }
                }
            }
            .extension{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 100px;
                .title{
                    width: 418px;
                    height: 27px;
                }
            }
        }
        .handle-extension{
            width: 100%;
            height: 98px;
            background:rgba(0, 0, 0, .4);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .btn{
                width: 403px;
                height: 65px;
                background: linear-gradient(90deg, #FF7200, #FFA200);
                border-radius: 32px;
                font-size: 25px;
                //font-family: FZQingKeBenYueSongS-R-GB;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 65px;
                text-align: center;
                cursor: pointer;
            }
        }
}
.extension-main {
    .phone-wrap{
                display: flex;
                justify-content: center;
                margin-bottom:92px;
                margin-top: 68px;
                .model{
                    width: 306px;
                    height: 523px;
                    position: relative;
                    .phone-item{
                        width: 306px;
                        height: 523px;
                        .phone-item-img{
                            width: 306px;
                            height: 523px;
                        }
                    }
                    .phone-item-other{
                            position: absolute;
                            top: 106.48px;
                            left: -247px;
                            width: 321px;
                            height: 95.6px;
                            background-image: url('~@/img/vioce/phone-text-1.png');
                            background-repeat: no-repeat;
                            background-size: cover;
                            z-index: 2;
                        .phone-item-text{
                            margin-top: 29px;
                            margin-left: 29px;
                            width: 171px;
                            font-size: 14px;
                            //font-family: FZQingKeBenYueSongS-R-GB;
                            font-weight: bold;
                            color: #FF7800;
                            line-height: 23px;
                        }
                        .phone-item-text-three{
                            margin-left: 134px;
                        }
                    }
                    .phone-item-other-secend{
                        top: 442px;
                        left: -244px;
                        width: 321px;
                        height: 94px;
                        background-image: url('~@/img/vioce/phone-text-2.png');
                    }
                    .phone-item-other-three{
                        top: 21px;
                        left: 242px;
                        width: 313px;
                        height: 188px;
                        background-image: url('~@/img/vioce/phone-text-3.png');
                    }
                    .phone-item-other-fourth{
                        top: 360px;
                        left: 236px;
                        width: 315px;
                        height: 95px;
                        background-image: url('~@/img/vioce/phone-text-4.png');
                    }
                }
            }
}
img{
          width: 100%;
          height: 100%;
}
.fade-enter-active, .fade-leave-active{
            transition:opacity 1s
}
.fade-enter, .fade-leave-to{
    opacity: 0;
}
.yyhb-img1-4{
  width: 387px;
  height: 327px;
  img{
    width: 100%;
    height: 100%;
  }
}
.section-imgs{
  .yyhb-img1-5{
    width: 346px;
    height: 251px;
  } 
  .flow-img2-1{
    width: 387px;
    height: 257px;
  }
}

@media screen and (min-width: 1496px){
  .advantage-list{
      width: 1200px!important;
  }
}
.fade-enter{
        opacity: 0;
      }
      .fade-enter-active{
        transition: opacity 1s;
      }
      /* .fade-enter-to{
        color: red;
      }
      .fade-leave{
        color: green;
      } */
      .fade-leave-to{
        opacity: 0;
      }
      .fade-leave-active{
        transition: opacity 1s;
      }
</style>
